import React from 'react';
import PropTypes from 'prop-types';
import Description from './Description.js';
import SubheadWrap from './SubheadWrap';

const formatHeading = (options = {}) => {
  const {
    title = '',
    range = '',
    headingLevel = 'h5'
  } = options;

  if (!title) return null;

  return (
    <SubheadWrap>
      <h3 className={`${headingLevel} mb-1`}>
        {title}{range && <span className="d-inline-block h6 ml-2 mb-0 font-weight-normal">{range}</span>}
      </h3>
    </SubheadWrap>
  );
};

function infoSection({ id, title, data, children }) {
  const hasData = Array.isArray(data) && data.length;
  const breakOn = hasData ? Math.ceil(data.length / 2) : 0;
  let content;

  if (!id) return null;

  if (hasData) {

    if (id === 'skills') {
      content = [];

      data.forEach((d, i) => {

        const {
          category = '',
          items = []
        } = d;

        const column = i < breakOn ? 0 : 1;

        if (!(column in content)) {
          content.push([]);
        }

        content[column].push(
          <div key={i} className="row">
            <div className={'col-12' + (data.length !== (i + 1) ? ' mb-3' : '')}>
              <div className="d-inline-block">
                {formatHeading({ title: category, headingLevel: 'h6' })}
              </div>

              {
                d.items.length ? (
                  <ul className="unstyled-list pl-0 mb-0">
                    {items.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                ) : null
              }
            </div>
          </div>
        );
      });

      content = content.map((item, i) => {
        return (
          <div key={i} className="col-6">
            {item}
          </div>
        );
      });
      
    } else {
      content = data.map((d, i) => {
        const {
          start = '',
          end = '',
          program = '',
          title = '',
          graduated = '',
          role = '',
          description = '',
          leadin = ''
        } = d;

        let range = '';

        if (program) {
          range += graduated ? `(${graduated})` : ''; 
        } else {
          range += start ? ` (${start} –` : '';
          range += end ? ` ${end})` : ')';
        }

        return (
          <React.Fragment key={i}>
            <div className="col-12 mb-3">
              <div className="row">
                <div className="col-12 mb-1">
                  {formatHeading({ title, range })}
                </div>

                {
                  (program || role) && (
                    <div className="col-12">
                      <h4 className="h6 font-weight-normal font-italic">{program}{role}</h4>
                    </div>
                  )
                }

                {
                  description && (
                    <div className="col-12 mt-2 mb-3">
                      <Description leadin={leadin} contentStr={description}></Description>
                    </div>
                  )
                }
              </div>
            </div>
          </React.Fragment>
        );
      }); 
    }
  }

  const isAbout = id === 'about-me';

  return (
    <section className={(isAbout ? 'pr-lg-0 my-5 mt-lg-4 ' : '') + 'info-section position-relative'}>
      {id !== 'about-me' && 
        <h2 className="h4 d-sm-block mb-3 text-center text-sm-left">
          <a href={'#' + id} className="text-decoration-none">
            {title || id.split('-').map(word => word[0].toUpperCase() + word.substr(1)).join('-').replace('-', ' ')}
          </a>
          <div className="border-bottom border-thick border-grey-light mb-2 mt-1"></div>
        </h2>
      }
      {children}
      
      <div className="row">{content}</div>
    </section>
  ); 
}

infoSection.propTypes = {
  id: PropTypes.string.isRequired
};

export default infoSection;
