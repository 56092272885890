 import React from 'react';
import DOMPurify from 'dompurify';

class Description extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: `description-${crypto.randomUUID().toString()}`,
            collapsed: false
        }

        this.initHeight = null;
        this.clickHandler = this.clickHandler.bind(this);
        this.readMoreRef = React.createRef();
    }

    clickHandler() {
        this.setState(state => {
            const { collapsed } = state;
            
            return {
                ...state,
                ...{ collapsed: !collapsed }
            }
        })
    }

    componentDidMount() {
        if (!this.initHeight) {
            this.initHeight = this.readMoreRef.current.getBoundingClientRect().height;
        }

        this.setState(state => {
            return {
                ...state,
                ...{ collapsed: !this.collapsed }
            }
        }, () => {
            this.updateTabIndexes();
        });
    }

    componentDidUpdate() {
        this.updateTabIndexes();
    }

    updateTabIndexes() {
        this.readMoreRef.current.querySelectorAll('a').forEach(el => {
            el.setAttribute('tabindex', this.state.collapsed ? '-1' : '0');
        });
    }

    render() {
        const {
            contentStr = '',
            leadin = ''
        } = this.props;

        const { id, collapsed } = this.state;
        const { clickHandler, readMoreRef, initHeight } = this;

        return (
            <React.Fragment>
                <div className="description">
                    <p>{leadin}{collapsed && <span>..</span>}</p>
                    <div id={id} ref={readMoreRef} className={`transition-all overflow-hidden ${collapsed ? ' collapsed' : ''}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contentStr) }} aria-hidden={collapsed ? 'true' : 'false'} aria-expanded={collapsed ? 'false' : 'true'} style={{ maxHeight: collapsed ? '0' : initHeight }}></div>
                </div>

                <button className="read-more bg-transparent p-0 m-0 border-0 small opacity-5 transition-all" aria-controls={id} onClick={clickHandler}>
                    {collapsed ? 'Read more' : 'Read less'}
                </button>
            </React.Fragment>
        )
    }
}

export default Description;
