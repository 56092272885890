const data = {
  'work-experience': [
    {
      title: 'D2L',
      role: 'Web Developer',
      leadin: 'My duties involve maintaining and making updates to the website (d2l.com), creating reusable, responsive and accessible content blocks for landing pages, and ensuring that pages load quickly and efficiently.',
      description: `<p>I am responsible for improving day to day operations by integrating GitHub Actions into our workflow, allowing a many of our manual processes during deployment to be automated and keeping our development build tooling modernized and up to date.</p><p>I also had a hand in building many single page application landing pages using Vue.js for the purpose of converting prospects into potential buyers of the company's software solutions.</p>`,
      start: '2019',
      icon: ''
    },
    {
      title: 'NovaSans',
      role: 'Web Developer',
      leadin: 'Initially a collaboration by myself and a colleague as recent graduates in order to build a portfolio which we then parlayed into a business.',
      description: `<p>Starting out as two designers, I eventually assumed the role of developer as my interest in web development and tech grew.</p><p>We worked with many small local businesses throughout the London, Ontario area, helping to improve their web presence or getting them started entirely.</p><p>During this time, we were also responsible for creating <strong>e3recap.com</strong> (now known as <a href="https://gamesrecap.io">gamesrecap.io</a>) which became an invaluable resource for those in the broader video game community who follow the latest releases and updates in the industry.</p>`,
      start: '2015',
      end: '2020',
      icon: ''
    },
  ],
  'skills': [
    // {
    //   category: 'Soft Skills',
    //   color: '',
    //   items: [
    //     'Problem Solving',
    //     'Approachable',
    //     'Patient',
    //     'Accountable',
    //     'Respectul',
    //     'Inquisitive'
    //   ]
    // },
    {
      category: 'JavaScript',
      color: '',
      items: [
        'ES6',
        'Node.js',
        'React',
        'Vue.js',
      ]
    },
    {
      category: 'PHP',
      color: '',
      items: [
        'WordPress',
        'WooCommerce',
        'Laravel'
      ]
    },
    {
      category: 'Development Tools',
      color: '',
      items: [
        'Git',
        'Webpack',
        'Vite',
        'Docker',
        'Visual Studio Code',
        'Bash/Unix Shell'
      ]
    },
    {
      category: 'Database',
      color: '',
      items: [
        'MongoDB',
        'Firebase',
        'SQL'
      ]
    },
    {
      category: 'CI/CD',
      color: '',
      items: [
        'GitHub Actions',
        'CircleCI'
      ]
    },
    {
      category: 'Testing Tools/Frameworks',
      color: '',
      items: [
        'Mocha.js',
        'Chai.js',
        'Cypress.io',
        'Playwright'
      ]
    },
    {
      category: 'Misc',
      color: '',
      items: [
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Shopify',
        'Figma'
      ]
    }
  ],
  'education': [
    {
      title: 'Fanshawe College',
      program: 'Advanced Diploma – Graphic Design',
      graduated: '2013',
      icon: ''
    }
  ]
};

export default data;
